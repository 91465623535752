@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: "Poppins", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1; /* Ensure the background is behind other content */
}

.background-image {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.custom-list-item {
  position: relative;
  padding-left: 30px; /* Adjust as needed */
  line-height: 1.5;
}

.custom-list-item::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border: 4px solid #f2c96f; /* Your custom border color */
  border-radius: 50%;
  background-color: white;
  display: block;
}

.custom-list-item2 {
  position: relative;
  padding-left: 30px; /* Adjust as needed */
  line-height: 1.5;
}

.custom-list-item2::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 15px;
  height: 15px;
  border: 4px solid #005d8d; /* Your custom border color */
  border-radius: 50%;
  background-color: white;
  display: block;
}

.background-table-pcb {
  background: #000000 url("./Assets/pcb table 2.webp") no-repeat center;
  background-size: cover;
}
